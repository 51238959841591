import React from 'react';
import {
  DataGrid,
  GridColDef,
  esES,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridRenderCellParams,
} from '@mui/x-data-grid';
import { Button } from '@mui/material';
import {
  fetchOrders,
  generateOrderPDF,
  generateOrderXLS,
  updateOrder,
} from '../../../../services/orderService';
import { useAppSelector } from '../../../../hooks/storeHooks';

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
    </GridToolbarContainer>
  );
}

const useDeletedOrdersState = () => {
  const { orders } = useAppSelector((state: any) => state.orderReducer);
  return {
    deletedOrders: orders.filter((order: Order) => order.deleted === true),
  };
};

const useColumns = () => {
  const columns: GridColDef[] = [
    {
      field: 'id',
      minWidth: 200,
      flex: 1,
      headerName: 'Numero Referencia',

      valueGetter(params) {
        const date = new Date(params.row.date);
        return date.getTime();
      },
    },
    {
      field: 'date',
      minWidth: 100,
      flex: 1,
      headerName: 'Fecha',
      valueGetter(params) {
        const date = new Date(params.row.date);
        return `${date.getDate()}/${
          date.getMonth() + 1
        }/${date.getFullYear()} `;
      },
      sortComparator: (v1, v2) => {
        const date1 = new Date(
          `${v1.split('/')[1]}-${v1.split('/')[0]}-${v1.split('/')[2]}`
        );
        const date2 = new Date(
          `${v2.split('/')[1]}-${v2.split('/')[0]}-${v2.split('/')[2]}`
        );
        const date1Time = date1.getTime();
        const date2Time = date2.getTime();
        return date1Time - date2Time;
      },
    },
    {
      field: 'customer',
      minWidth: 200,
      flex: 1,
      headerName: 'Cliente',
      valueGetter(params) {
        return params.row.customer.name;
      },
    },
    {
      field: 'total',
      minWidth: 100,
      flex: 1,
      headerName: 'Total Neto',
      valueGetter(params) {
        return params.row.discountedNetTotal.toLocaleString('es-CL', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        });
      },
    },
    {
      field: 'totalProducts',
      minWidth: 100,
      flex: 1,
      headerName: 'Total Productos',
      valueGetter(params) {
        return params.row.products.length;
      },
    },
    {
      field: 'pdf',
      minWidth: 150,
      flex: 1,
      headerName: 'Descargar PDF',
      renderCell: (params: GridRenderCellParams) => (
        <Button
          sx={{ backgroundColor: '#EC0013', color: 'white' }}
          onClick={() => {
            const date = new Date(params.row.date);
            const downloadName = `${date.getTime()}`;

            generateOrderPDF(params.row._id, downloadName);
          }}
        >
          PDF
        </Button>
      ),
    },
    {
      field: 'excel',
      minWidth: 150,
      flex: 1,
      headerName: 'Descargar Excel',
      renderCell: (params: GridRenderCellParams) => (
        <Button
          sx={{ backgroundColor: '#1D6F42', color: 'white' }}
          onClick={() => {
            const date = new Date(params.row.date);
            const downloadName = `${date.getTime()}`;
            generateOrderXLS(params.row._id, downloadName);
          }}
        >
          EXCEL
        </Button>
      ),
    },
    {
      field: 'deleted',
      minWidth: 150,
      flex: 1,
      headerName: 'Recuperar',
      renderCell: (params: GridRenderCellParams) => (
        <Button
          sx={{ backgroundColor: 'green', color: 'white' }}
          onClick={async () => {
            await updateOrder({ deleted: !params.row.deleted }, params.row._id);
            await fetchOrders();
          }}
        >
          Recuperar
        </Button>
      ),
    },
  ];
  return columns;
};

const useDataGrid = (deletedOrders: Order[], columns: GridColDef[]) => {
  return (
    <DataGrid
      sx={{
        padding: 2,
        '& .super-app-theme--true': {
          bgcolor: '#9ACD32',
        },
      }}
      getRowClassName={(params) => `super-app-theme--${params.row.sentToERP}`}
      autoHeight={true}
      columns={columns}
      rows={deletedOrders}
      pageSize={20}
      getRowId={(row) => row._id}
      localeText={esES.components.MuiDataGrid.defaultProps.localeText}
      components={{ Toolbar: CustomToolbar }}
    />
  );
};

interface Props {
  deletedOrders: Order[];
}

const DeletedOrdersViewManager: React.FC = () => {
  const { deletedOrders } = useDeletedOrdersState();
  const columns = useColumns();
  const dataGrid = useDataGrid(deletedOrders, columns);

  return <>{dataGrid}</>;
};

const DeletedOrdersViewTypist: React.FC<Props> = ({ deletedOrders }) => {
  const columns = useColumns();
  const dataGrid = useDataGrid(deletedOrders, columns);

  return <>{dataGrid}</>;
};

export { DeletedOrdersViewManager, DeletedOrdersViewTypist };
