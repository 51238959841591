interface State {
  sellers: Seller[];
  isLoading: boolean;
}

const initialState: State = {
  sellers: [],
  isLoading: false,
};

export enum SellerActions {
  RequestSellers = "REQUEST_SELLERS",
  ReceiveSellers = "RECEIVE_SELLERS",
  AddSeller = "ADD_SELLER",
  DeleteSeller = "DELETE_SELLER",
}
// el initial state se pone para empezar los estados, las acciones son lo que modifican los estados
const reducer = (
  state: State = initialState,
  { type, payload }: { type: SellerActions; payload: any }
): State => {
  switch (type) {
    case SellerActions.RequestSellers: //hago el llamado para que la api me traiga los producots
      return { ...state, isLoading: true };
    case SellerActions.ReceiveSellers: //recibo el arreglo de productos
      return { ...state, sellers: payload, isLoading: false };
    case SellerActions.AddSeller: //agrego un producto
      return {
        ...state,
        sellers: [
          ...state.sellers.filter((p) => p._id !== payload._id),
          payload,
        ],
      };
    case SellerActions.DeleteSeller:
      return {
        ...state,
        sellers: state.sellers.filter((p) => p._id !== payload._id),
      };
    default:
      return state;
  }
};

export default reducer;
