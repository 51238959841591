import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "./AuthReducer";
import sellerReducer from "./SellerReducer";
import productReducer from "./ProductReducer";
import customerReducer from "./CustomerReducer";
import orderReducer from "./OrderReducer";
import quotationReducer from "./QuotationReducer";
import supervisorReducer from "./SupervisorReducer";
import invoiceReducer from "./InvoiceReducer";
import truckReducer from "./TrucksReducer";
import typistReducer from "./TypistReducer";
import productListFilesReducer from "./ProductListFileReducer";
import statisticsReducer from "./StatisticsReducer";
import productStatisticReducer from "./ProductStatisticsReducer";
import pendingReducer from "./PendingReducer";
import UserReducer from "./UserReducer";

const rootReducer = combineReducers({
  authReducer,
  supervisorReducer,
  sellerReducer,
  productReducer,
  productListFilesReducer,
  customerReducer,
  orderReducer,
  quotationReducer,
  invoiceReducer,
  truckReducer,
  typistReducer,
  statisticsReducer,
  productStatisticReducer,
  pendingReducer,
  UserReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
