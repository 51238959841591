import {
  AppBar,
  Drawer,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Toolbar,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { mdiLogoutVariant, mdiMenu } from "@mdi/js";
import Icon from "@mdi/react";
import { useState } from "react";
import {
  ManagerRoutes,
  SupervisorRoutes,
  TypistRoutes,
  Routes,
} from "../../../../config/Routes";
import { AuthActions } from "../../../../reducers/AuthReducer";
import store from "../../../../store";

interface MenuProps {
  role: string;
  authorizedViews: string[];
}

const Menu: React.FC<MenuProps> = ({ role, authorizedViews }) => {
  const history = useLocation();
  const [isNavOpen, setIsNavOpen] = useState(false);
  const route = history.pathname;

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    return store.dispatch({ type: AuthActions.DelSession, payload: null });
  };

  const getMenuItems = () => {
    const menuItems = [];

    if (role === "manager") {
      menuItems.push(
        <Link to={Routes.Home} key="home">
          <div
            onClick={() => setIsNavOpen(false)}
            className={`${route === Routes.Home ? "text-archiLightBlue" : ""}`}
          >
            Inicio
          </div>
        </Link>
      );

      menuItems.push(
        <Link to={ManagerRoutes.Orders} key="orders">
          <div
            onClick={() => setIsNavOpen(false)}
            className={`${route === ManagerRoutes.Orders ? "text-archiLightBlue" : ""}`}
          >
            Ordenes
          </div>
        </Link>
      );

      menuItems.push(
        <Link to={ManagerRoutes.Quotations} key="quotations">
          <div
            onClick={() => setIsNavOpen(false)}
            className={`${route === ManagerRoutes.Quotations ? "text-archiLightBlue" : ""}`}
          >
            Cotizaciones
          </div>
        </Link>
      );

      menuItems.push(
        <Link to={ManagerRoutes.Sellers} key="sellers">
          <div
            onClick={() => setIsNavOpen(false)}
            className={`${route === ManagerRoutes.Sellers ? "text-archiLightBlue" : ""}`}
          >
            Vendedores
          </div>
        </Link>
      );

      menuItems.push(
        <Link to={Routes.Achievements} key="achievements">
          <div
            onClick={() => setIsNavOpen(false)}
            className={`${route === Routes.Achievements ? "text-archiLightBlue" : ""}`}
          >
            Logros Vendedores
          </div>
        </Link>
      );

      menuItems.push(
        <Link to={ManagerRoutes.Supervisors} key="supervisors">
          <div
            onClick={() => setIsNavOpen(false)}
            className={`${route === ManagerRoutes.Supervisors ? "text-archiLightBlue" : ""}`}
          >
            Supervisores
          </div>
        </Link>
      );

      menuItems.push(
        <Link to={ManagerRoutes.Typists} key="typists">
          <div
            onClick={() => setIsNavOpen(false)}
            className={`${route === ManagerRoutes.Typists ? "text-archiLightBlue" : ""}`}
          >
            Digitadores
          </div>
        </Link>
      );

      menuItems.push(
        <Link to={ManagerRoutes.Products} key="products">
          <div
            onClick={() => setIsNavOpen(false)}
            className={`${route === ManagerRoutes.Products ? "text-archiLightBlue" : ""}`}
          >
            Lista de Productos
          </div>
        </Link>
      );

      menuItems.push(
        <Link to={ManagerRoutes.ProductList} key="productList">
          <div
            onClick={() => setIsNavOpen(false)}
            className={`${route === ManagerRoutes.ProductList ? "text-archiLightBlue" : ""}`}
          >
            Catalogo
          </div>
        </Link>
      );

      menuItems.push(
        <Link to={ManagerRoutes.Clients} key="clients">
          <div
            onClick={() => setIsNavOpen(false)}
            className={`${route === ManagerRoutes.Clients ? "text-archiLightBlue" : ""}`}
          >
            Clientes
          </div>
        </Link>
      );

      menuItems.push(
        <Link to={ManagerRoutes.Reports} key="reports">
          <div
            onClick={() => setIsNavOpen(false)}
            className={`${route === ManagerRoutes.Reports ? "text-archiLightBlue" : ""}`}
          >
            Reportes
          </div>
        </Link>
      );

      menuItems.push(
        <Link to={ManagerRoutes.Statistics} key="statistics">
          <div
            onClick={() => setIsNavOpen(false)}
            className={`${route === ManagerRoutes.Statistics ? "text-archiLightBlue" : ""}`}
          >
            Estadisticas
          </div>
        </Link>
      );

      menuItems.push(
        <Link to={ManagerRoutes.Invoices} key="invoices">
          <div
            onClick={() => setIsNavOpen(false)}
            className={`${route === ManagerRoutes.Invoices ? "text-archiLightBlue" : ""}`}
          >
            Facturas
          </div>
        </Link>
      );

      menuItems.push(
        <Link to={ManagerRoutes.Pendings} key="pendings">
          <div
            onClick={() => setIsNavOpen(false)}
            className={`${route === ManagerRoutes.Pendings ? "text-archiLightBlue" : ""}`}
          >
            Pendientes
          </div>
        </Link>
      );

      menuItems.push(
        <Link to={ManagerRoutes.Inventories} key="inventories">
          <div
            onClick={() => setIsNavOpen(false)}
            className={`${route === ManagerRoutes.Inventories ? "text-archiLightBlue" : ""}`}
          >
            Inventarios
          </div>
        </Link>
      );

      menuItems.push(
        <Link to={ManagerRoutes.Users} key="users">
          <div
            onClick={() => setIsNavOpen(false)}
            className={`${route === ManagerRoutes.Users ? "text-archiLightBlue" : ""}`}
          >
            Usuarios
          </div>
        </Link>
      );
      menuItems.push(
        <Link to={ManagerRoutes.Picking} key="picking">
          <div
            onClick={() => setIsNavOpen(false)}
            className={`${route === ManagerRoutes.Picking ? "text-archiLightBlue" : ""}`}
          >
            Picking
          </div>
        </Link>);
    }

    if (role === "supervisor") {
      menuItems.push(
        <Link to={Routes.Home} key="home">
          <div
            onClick={() => setIsNavOpen(false)}
            className={`${route === Routes.Home ? "text-archiLightBlue" : ""}`}
          >
            Inicio
          </div>
        </Link>
      );

      menuItems.push(
        <Link to={SupervisorRoutes.Reports} key="reports">
          <div
            onClick={() => setIsNavOpen(false)}
            className={`${route === SupervisorRoutes.Reports ? "text-archiLightBlue" : ""}`}
          >
            Reportera
          </div>
        </Link>
      );

      menuItems.push(
        <Link to={Routes.Achievements} key="achievements">
          <div
            onClick={() => setIsNavOpen(false)}
            className={`${route === Routes.Achievements ? "text-archiLightBlue" : ""}`}
          >
            Logros Vendedores
          </div>
        </Link>
      );
    }

    if (role === "typist") {
      menuItems.push(
        <Link to={TypistRoutes.Orders} key="orders">
          <div
            onClick={() => setIsNavOpen(false)}
            className={`${route === TypistRoutes.Orders ? "text-archiLightBlue" : ""}`}
          >
            Ordenes
          </div>
        </Link>
      );
    }

    if (role === "employee") {
      menuItems.push(
        <Link to={Routes.Home} key="home">
          <div
            onClick={() => setIsNavOpen(false)}
            className={`${route === Routes.Home ? "text-archiLightBlue" : ""}`}
          >
            Inicio
          </div>
        </Link>
      );
    }

    // Agregar vistas autorizadas adicionales para roles que no sean "manager"
    if (role !== "manager") {
      if (authorizedViews.includes("Pendientes")) {
        menuItems.push(
          <Link to={ManagerRoutes.Pendings} key="pendings">
            <div
              onClick={() => setIsNavOpen(false)}
              className={`${route === ManagerRoutes.Pendings ? "text-archiLightBlue" : ""}`}
            >
              Pendientes
            </div>
          </Link>
        );
      }

      if (authorizedViews.includes("Inventarios")) {
        menuItems.push(
          <Link to={ManagerRoutes.Inventories} key="inventories">
            <div
              onClick={() => setIsNavOpen(false)}
              className={`${route === ManagerRoutes.Inventories ? "text-archiLightBlue" : ""}`}
            >
              Inventarios
            </div>
          </Link>
        );
      }

      if (authorizedViews.includes("Facturas")) {
        menuItems.push(
          <Link to={ManagerRoutes.Invoices} key="invoices">
            <div
              onClick={() => setIsNavOpen(false)}
              className={`${route === ManagerRoutes.Invoices ? "text-archiLightBlue" : ""}`}
            >
              Facturas
            </div>
          </Link>
        );
      }

      if (authorizedViews.includes("Estadisticas")) {
        menuItems.push(
          <Link to={ManagerRoutes.Statistics} key="statistics">
            <div
              onClick={() => setIsNavOpen(false)}
              className={`${route === ManagerRoutes.Statistics ? "text-archiLightBlue" : ""}`}
            >
              Estadisticas
            </div>
          </Link>
        );
      }

      if (authorizedViews.includes("Clientes")) {
        menuItems.push(
          <Link to={ManagerRoutes.Clients} key="clients">
            <div
              onClick={() => setIsNavOpen(false)}
              className={`${route === ManagerRoutes.Clients ? "text-archiLightBlue" : ""}`}
            >
              Clientes
            </div>
          </Link>
        );
      }

      if (authorizedViews.includes("Lista de Productos")) {
        menuItems.push(
          <Link to={ManagerRoutes.Products} key="products">
            <div
              onClick={() => setIsNavOpen(false)}
              className={`${route === ManagerRoutes.Products ? "text-archiLightBlue" : ""}`}
            >
              Lista de Productos
            </div>
          </Link>
        );
      }

      if (authorizedViews.includes("Catalogo")) {
        menuItems.push(
          <Link to={ManagerRoutes.ProductList} key="productList">
            <div
              onClick={() => setIsNavOpen(false)}
              className={`${route === ManagerRoutes.ProductList ? "text-archiLightBlue" : ""}`}
            >
              Catalogo
            </div>
          </Link>
        );
      }

      if (authorizedViews.includes("Picking")) {
        menuItems.push(
          <Link to={ManagerRoutes.Picking} key="picking">
            <div
              onClick={() => setIsNavOpen(false)}
              className={`${route === ManagerRoutes.Picking ? "text-archiLightBlue" : ""}`}
            >
              Picking
            </div>
          </Link>
        );
      }
    }

    return menuItems;
  };

  return (
    <div>
      <AppBar position="fixed">
        <Toolbar className="flex justify-between">
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={() => setIsNavOpen(true)}
          >
            <Icon path={mdiMenu} size={1} />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Drawer
        anchor="left"
        PaperProps={{ sx: { backgroundColor: "#003755", color: "white" } }}
        open={isNavOpen}
        onClose={() => setIsNavOpen(false)}
      >
        <div className="flex flex-col space-y-4 p-4">
          {getMenuItems()}
          <div
            onClick={handleLogout}
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          >
            <ListItemText>Cerrar sesion</ListItemText>
            <ListItemIcon
              sx={{ color: "inherit", minWidth: "0", marginRight: "8px" }}
            >
              <Icon className="w-7" path={mdiLogoutVariant}></Icon>
            </ListItemIcon>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default Menu;
