import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

const handleGeneratePendingSkusExcel = async (data) => {
     // Crear un nuevo libro de trabajo
     const workbook = new ExcelJS.Workbook();
     const worksheet = workbook.addWorksheet('Total Skus Pendientes');

     worksheet.columns = [
        { header: 'SKU', key: 'sku', width: 10 },
        { header: 'descripcion', key: 'description', width: 32 },
        { header: 'proveedor', key: 'supplier', width: 20 },
        { header: 'cantidad pendiente', key: 'pendingQuantity', width: 20 },
        { header: 'precio total', key: 'price', width: 20 },
        { header: 'stock actual', key: 'stock', width: 20 },
     ];

     data.forEach((item) => {
        worksheet.addRow(item);
     });

     // Generar archivo Excel y guardarlo
     const buffer = await workbook.xlsx.writeBuffer();
     const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
     saveAs(blob, 'Listado.xlsx');
}


export default handleGeneratePendingSkusExcel