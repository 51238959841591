import axios from "./client";
import store from "../store";
import { StatisticsActions } from "../reducers/StatisticsReducer";
import FileDownload from "js-file-download";
const API_URL = process.env.REACT_APP_API_STATISTICS_URL;

export const fetchSalesSellerMonth = async (branch?: string) => {
    store.dispatch({ type: StatisticsActions.RequestStatistics, payload: {} });
    const { data } = await axios.post(`${API_URL}sales/seller`, { branch });
    store.dispatch({
        type: StatisticsActions.ReceiveStatistics,
        payload: data.data.salesSellerMonth,
    });
};

export const fetchSalesBranchMonth = async () => {
    store.dispatch({ type: StatisticsActions.RequestStatistics, payload: {} });
    const { data } = await axios.post(`${API_URL}/sales/branch`);
    store.dispatch({
        type: StatisticsActions.ReceiveStatistics,
        payload: data.data.salesBranchMonth,
    });
};

export const fetchSalesClientMonth = async (seller?: string) => {
    store.dispatch({ type: StatisticsActions.RequestStatistics, payload: {} });
    const { data } = await axios.post(`${API_URL}sales/customer`, { seller });
    store.dispatch({
        type: StatisticsActions.ReceiveStatistics,
        payload: data.data.salesCustomerMonth,
    });
}

export const fetchSalesFamilyMonth = async (seller?: string) => {
    store.dispatch({ type: StatisticsActions.RequestStatistics, payload: {} });
    const { data } = await axios.post(`${API_URL}sales/family`, { seller });
    store.dispatch({
        type: StatisticsActions.ReceiveStatistics,
        payload: data.data.salesFamilyMonth,
    });
}

export const fetchSalesBrandMonth = async (seller?: string) => {
    store.dispatch({ type: StatisticsActions.RequestStatistics, payload: {} });
    const { data } = await axios.post(`${API_URL}sales/brand`, { seller });
    store.dispatch({
        type: StatisticsActions.ReceiveStatistics,
        payload: data.data.salesBrandMonth,
    });
}

export const fetchSalesSupplierMonth = async (seller?: string) => {
    store.dispatch({ type: StatisticsActions.RequestStatistics, payload: {} });
    const { data } = await axios.post(`${API_URL}sales/supplier`, { seller });
    store.dispatch({
        type: StatisticsActions.ReceiveStatistics,
        payload: data.data.salesSupplierMonth,
    });
}

export const fetchRankingProducts = async (family?: string) => {
    store.dispatch({ type: StatisticsActions.RequestStatistics, payload: {} });
    const a = await axios.post(`${API_URL}sales/ranking`, { family });
    store.dispatch({
        type: StatisticsActions.ReceiveStatistics,
        payload: a.data.data.salesRankingProductMonth,
    });
}

export const fetchMarginFamilyMonth = async () => {
    store.dispatch({ type: StatisticsActions.RequestStatistics, payload: {} });
    const { data } = await axios.get(`${API_URL}margin/family`);
    store.dispatch({
        type: StatisticsActions.ReceiveStatistics,
        payload: data.data.marginPerFamilyMonth,
    });
}

export const fetchMarginSupplierMonth = async () => {
    store.dispatch({ type: StatisticsActions.RequestStatistics, payload: {} });
    const { data } = await axios.get(`${API_URL}margin/supplier`);
    store.dispatch({
        type: StatisticsActions.ReceiveStatistics,
        payload: data.data.marginPerSupplierMonth,
    });
}

export const fetchMarginBrandMonth = async () => {
    store.dispatch({ type: StatisticsActions.RequestStatistics, payload: {} });
    const { data } = await axios.get(`${API_URL}margin/brand`);
    store.dispatch({
        type: StatisticsActions.ReceiveStatistics,
        payload: data.data.marginPerBrandMonth,
    });
}

export const getFamiliesNames = async () => {
    const { data } = await axios.get(`${API_URL}families`);
    return data.data.families.map((family: any) => family.name);
}

export const downloadSalesSellerMonthXLS = async (downloadName: string) => {
    await axios
        .get(API_URL + `sales/seller/download`, {
            responseType: "blob",
            headers: {
                "content-type": "application/vnd.ms-excel;charset=UTF-8",
            },
        })
        .then((res) => {
            FileDownload(res.data, `${downloadName}.xlsx`);
        });
};

export const downloadSalesBranchMonthXLS = async (downloadName: string) => {
    await axios
        .get(API_URL + `sales/branch/download`, {
            responseType: "blob",
            headers: {
                "content-type": "application/vnd.ms-excel;charset=UTF-8",
            },
        })
        .then((res) => {
            FileDownload(res.data, `${downloadName}.xlsx`);
        });
}

export const downloadSalesCustomerSellerMonthXLS = async (downloadName: string) => {
    await axios
        .get(API_URL + `sales/customer/download`, {
            responseType: "blob",
            headers: {
                "content-type": "application/vnd.ms-excel;charset=UTF-8",
            },
        })
        .then((res) => {
            FileDownload(res.data, `${downloadName}.xlsx`);
        });
}

export const downloadSalesSupplierSellerMonthXLS = async (downloadName: string) => {
    await axios
        .get(API_URL + `sales/supplier/download`, {
            responseType: "blob",
            headers: {
                "content-type": "application/vnd.ms-excel;charset=UTF-8",
            },
        })
        .then((res) => {
            FileDownload(res.data, `${downloadName}.xlsx`);
        });
}

export const downloadSalesFamilySellerMonthXLS = async (downloadName: string) => {
    await axios
        .get(API_URL + `sales/family/download`, {
            responseType: "blob",
            headers: {
                "content-type": "application/vnd.ms-excel;charset=UTF-8",
            },
        })
        .then((res) => {
            FileDownload(res.data, `${downloadName}.xlsx`);
        });
}

export const downloadSalesBrandSellerMonthXLS = async (downloadName: string) => {
    await axios
        .get(API_URL + `sales/brand/download`, {
            responseType: "blob",
            headers: {
                "content-type": "application/vnd.ms-excel;charset=UTF-8",
            },
        })
        .then((res) => {
            FileDownload(res.data, `${downloadName}.xlsx`);
        });
}

export const downloadSalesProductRankingMonthXLS = async (downloadName: string) => {
    await axios
        .get(API_URL + `sales/ranking/sales/download`, {
            responseType: "blob",
            headers: {
                "content-type": "application/vnd.ms-excel;charset=UTF-8",
            },
        })
        .then((res) => {
            FileDownload(res.data, `${downloadName}.xlsx`);
        });
}

export const downloadQuantityProductRankingMonthXLS = async (downloadName: string) => {
    await axios
        .get(API_URL + `sales/ranking/quantity/download`, {
            responseType: "blob",
            headers: {
                "content-type": "application/vnd.ms-excel;charset=UTF-8",
            },
        })
        .then((res) => {
            FileDownload(res.data, `${downloadName}.xlsx`);
        });
}

