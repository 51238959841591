import React from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';

interface ViewTargetsModalProps {
    open: boolean;
    onClose: () => void;
    seller: Seller | null;
}

const ViewTargetsModal: React.FC<ViewTargetsModalProps> = ({ open, onClose, seller }) => {
    if (!seller) return null;

    const formatCurrency = (value: number) => {
        return value.toLocaleString('es-CL', { style: 'currency', currency: 'CLP' });
    };

    const quarterlyTargets = {
        Q1: seller.januaryTarget + seller.februaryTarget + seller.marchTarget,
        Q2: seller.aprilTarget + seller.mayTarget + seller.juneTarget,
        Q3: seller.julyTarget + seller.augustTarget + seller.septemberTarget,
        Q4: seller.octoberTarget + seller.novemberTarget + seller.decemberTarget,
    };

    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={{ p: 4, backgroundColor: 'white', margin: 'auto', maxWidth: 500 }}>
                <Typography variant="h6" gutterBottom>
                    Metas de {seller.name}
                </Typography>
                <Typography variant="body1">Enero: {formatCurrency(seller.januaryTarget)}</Typography>
                <Typography variant="body1">Febrero: {formatCurrency(seller.februaryTarget)}</Typography>
                <Typography variant="body1">Marzo: {formatCurrency(seller.marchTarget)}</Typography>
                <Typography variant="body1">Abril: {formatCurrency(seller.aprilTarget)}</Typography>
                <Typography variant="body1">Mayo: {formatCurrency(seller.mayTarget)}</Typography>
                <Typography variant="body1">Junio: {formatCurrency(seller.juneTarget)}</Typography>
                <Typography variant="body1">Julio: {formatCurrency(seller.julyTarget)}</Typography>
                <Typography variant="body1">Agosto: {formatCurrency(seller.augustTarget)}</Typography>
                <Typography variant="body1">Septiembre: {formatCurrency(seller.septemberTarget)}</Typography>
                <Typography variant="body1">Octubre: {formatCurrency(seller.octoberTarget)}</Typography>
                <Typography variant="body1">Noviembre: {formatCurrency(seller.novemberTarget)}</Typography>
                <Typography variant="body1">Diciembre: {formatCurrency(seller.decemberTarget)}</Typography>
                <Typography variant="h6" gutterBottom>
                    Metas Trimestrales
                </Typography>
                <Typography variant="body1">Q1: {formatCurrency(quarterlyTargets.Q1)}</Typography>
                <Typography variant="body1">Q2: {formatCurrency(quarterlyTargets.Q2)}</Typography>
                <Typography variant="body1">Q3: {formatCurrency(quarterlyTargets.Q3)}</Typography>
                <Typography variant="body1">Q4: {formatCurrency(quarterlyTargets.Q4)}</Typography>
                <Button onClick={onClose} variant="contained" sx={{ mt: 2 }}>
                    Cerrar
                </Button>
            </Box>
        </Modal>
    );
};

export default ViewTargetsModal;
