interface State {
    supervisors: Supervisor[];
    isLoading: boolean;
}

const initialState: State = {
    supervisors: [],
    isLoading: false,
};

export enum SupervisorActions {
    RequestSupervisors = "REQUEST_SUPERVISORS",
    ReceiveSupervisors = "RECEIVE_SUPERVISORS",
    AddSupervisor = "ADD_SUPERVISOR",
    DeleteSupervisor = "DELETE_SUPERVISOR",
}
// el initial state se pone para empezar los estados, las acciones son lo que modifican los estados
const reducer = (
    state: State = initialState,
    { type, payload }: { type: SupervisorActions; payload: any }
): State => {
    switch (type) {
        case SupervisorActions.RequestSupervisors:
            //hago el llamado para que la api me traiga los producots
            return { ...state, isLoading: true };
        case SupervisorActions.ReceiveSupervisors:
            //recibo el arreglo de productos
            return { ...state, supervisors: payload, isLoading: false };
        case SupervisorActions.AddSupervisor: //agrego un producto
            return {
                ...state,
                supervisors: [
                    ...state.supervisors.filter((p) => p._id !== payload._id),
                    payload,
                ],
            };
        case SupervisorActions.DeleteSupervisor:
            return {
                ...state,
                supervisors: state.supervisors.filter((p) => p._id !== payload._id),
            };
        default:
            return state;
    }
};

export default reducer;
