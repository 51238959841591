import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { Cell } from 'recharts';

const handleGenerateWorkerAchievementExcel = async (data, month, quarter) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(`Ventas por periodo`);

    const monthNames = {
        0: "Enero",
        1: "Febrero",
        2: "Marzo",
        3: "Abril",
        4: "Mayo",
        5: "Junio",
        6: "Julio",
        7: "Agosto",
        8: "Septiembre",
        9: "Octubre",
        10: "Noviembre",
        11: "Diciembre"
    };
    const columns = [
        { header: 'Vendedor', key: 'name', width: 20 },
    ];


    for (let i = 0; i < 12; i++) {

        if (month > i) {
            columns.push(
                { header: `Facturado ${monthNames[i]}`, key: `monthSales_${i+1}`, width: 20 },
                { header: `Meta ${monthNames[i]}`, key: `monthTarget_${i+1}`, width: 20 },
                { header: `% Cumplimiento ${monthNames[i]}`, key: `monthPercentage_${i+1}`, width: 20 },
            );

            if (i % 3 === 2) {
                columns.push(
                    { header: `Facturado Trimestre ${Math.floor((i)/3)+1}`, key: `quarterSales_${Math.floor((i)/3)+1}`, width: 25 },
                    { header: `Meta Trimestre ${Math.floor((i)/3)+1}`, key: `quarterTarget_${Math.floor((i)/3)+1}`, width: 25 },
                    { header: `% Cumplimiento Trimestre ${Math.floor((i)/3)+1}`, key: `quarterPercentage_${Math.floor((i)/3)+1}`, width: 25 },
                );
            };
        };
    };

    if (month % 3 !== 0) {
        columns.push(
            { header: `Facturado Trimestre ${quarter}`, key: `quarterSales_${quarter}`, width: 25 },
            { header: `Meta Trimestre ${quarter}`, key: `quarterTarget_${quarter}`, width: 25 },
            { header: `% Cumplimiento Trimestre ${quarter}`, key: `quarterPercentage_${quarter}`, width: 25 },
        );
    };
    worksheet.columns = columns;


    let rows = [];
    data.forEach((seller) => {

        const row = {
            name: seller.name,
        };

        for (let j = 0; j < 12; j++) {
            if (month-j > 0) {
                row[`monthSales_${month-j}`] = seller.monthSales[j];
                row[`monthTarget_${month-j}`] = seller.monthTarget[j];
                row[`monthPercentage_${month-j}`] = seller.monthPercentages[j];
            };
        };
        for (let j = 0; j < 4; j++) {
            if (quarter-j > 0) {
                row[`quarterSales_${quarter-j}`] = seller.quarterSales[j];
                row[`quarterTarget_${quarter-j}`] = seller.quarterTargets[j];
                row[`quarterPercentage_${quarter-j}`] = seller.quarterPercentages[j];
            };
        };

        rows.push(row);
    });

    rows.forEach((row) => {
        worksheet.addRow(row);
    });


    worksheet.eachRow(function (row, rowNumber) {
        row.eachCell((cell, colNumber) => {
            if (rowNumber === 1) {
                cell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "f5b914" },
                };
            }

            cell.border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" },
            };

            if ((colNumber-1)%3 === 0) {
                if (parseInt(cell.value) < 50) {
                    cell.font = {
                        color: { argb: "FF0000" },
                    };
                } else if (parseInt(cell.value) < 95) {
                    cell.font = {
                        color: { argb: "FFAA00" },
                    };
                } else if (parseInt(cell.value) > 95) {
                    cell.font = {
                        color: { argb: "00FF00" },
                    };
                };
            };
            

        });
        row.commit();
    });



    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, 'Ventas_Y_Metas_Vendedores.xlsx');
};

export default handleGenerateWorkerAchievementExcel;
