import {
    DataGrid,
    GridColDef,
    esES,
    GridToolbarContainer,
    GridToolbarFilterButton,
    GridToolbarDensitySelector,
} from '@mui/x-data-grid';

import { useAppSelector } from '../../../../hooks/storeHooks';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Button } from '@mui/material';
import { fetchPendingSkus } from '../../../../services/invoiceService';
import handleGeneratePendingSkusExcel from '../../../../utils/createPendingSkusExcel';

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
        </GridToolbarContainer>
    );
}

const useColumns = () => {
    const columns: GridColDef[] = [
        {
            field: 'sku',
            minWidth: 200,
            flex: 1,
            headerName: 'SKU',
        },
        {
            field: 'description',
            minWidth: 300,
            flex: 1,
            headerName: 'Descripción',
        },
        {
            field: 'supplier',
            minWidth: 200,
            flex: 1,
            headerName: 'Proveedor',
        },
        {
            field: 'pendingQuantity',
            minWidth: 100,
            flex: 1,
            headerName: 'Cantidad Pendiente',
        },
        {
            field: 'price',
            minWidth: 100,
            flex: 1,
            headerName: 'Precio Total Pendiente',
        },
        {
            field: 'stock',
            minWidth: 100,
            flex: 1,
            headerName: 'Stock Actual',
        }
    ]

    return columns;
}


interface PendingSkusViewProps {
    startDate: Date | null;
    endDate: Date | null;
    setStartDate: (date: Date | null) => void;
    setEndDate: (date: Date | null) => void;
}

const PendingSkusView: React.FC<PendingSkusViewProps> = ({
    startDate,
    endDate,
    setStartDate,
    setEndDate,
}) => {
    const columns = useColumns();
    const { products } = useAppSelector((state) => state.productReducer);
    const pendingSkus = useAppSelector((state) => state.pendingReducer.pendingSkus);

    const rowsWithStock = pendingSkus.map((row) => {
        const product = products.find((product) => product.code === row.sku);
        return {
            ...row,
            supplier: product ? product.supplier : '',
            stock: product ? product.stockTotal : 0,
        }
    });

    return (
        <>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <div className="my-2 flex gap-2">
                    <DatePicker
                        sx={{ minWidth: '200px' }}
                        format='dd/MM/yyyy'
                        label="Fecha de inicio"
                        value={startDate}
                        onChange={(newValue) => {
                            if (newValue) {
                                newValue.setHours(0);
                                newValue.setMinutes(0);
                            }
                            setStartDate(newValue);
                        }}
                    />
                    <DatePicker
                        sx={{ minWidth: '200px' }}
                        format='dd/MM/yyyy'
                        label="Fecha de fin"
                        value={endDate}
                        onChange={(newValue) => {
                            if (newValue) {
                                newValue.setHours(23);
                                newValue.setMinutes(59);
                            }
                            setEndDate(newValue);
                        }}
                    />
                    <Button
                        variant='contained'
                        onClick={() => {
                            fetchPendingSkus(startDate, endDate);
                        }}
                    >
                        Actualizar
                    </Button>
                    <Button
                        variant="contained"
                        color='info'
                        onClick={
                            () => {
                                handleGeneratePendingSkusExcel(rowsWithStock);
                            }
                        }>Descargar excel</Button>
                </div>
            </LocalizationProvider>
            <DataGrid
                autoHeight
                rows={rowsWithStock}
                columns={columns}
                pageSize={100}
                components={{
                    Toolbar: CustomToolbar,
                }}
                getRowId={(row) => row.sku}
                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
            />
        </>
    );
}

export default PendingSkusView;