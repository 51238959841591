import { AuthActions } from "../reducers/AuthReducer";
import { UserActions } from "../reducers/UserReducer";
import store from "../store";
import client from "./client";

const API_URL = process.env.REACT_APP_API_URL;

export const login = async (username: string, password: string) => {
  try {
    const {
      data: { token, user },
    } = await client.post<{ token: string; user: { role: string } }>(
      `${API_URL}auth/login`,
      {
        username,
        password,
      }
    );
    if (user.role === "seller") {
      throw new Error("You are a seller");
    }
    if (user.role === "truck") {
      throw new Error("You are a Driver");
    }
    if (user.role === "logistic") {
      throw new Error("You are a Logistic");
    }
    store.dispatch({ type: AuthActions.SetSession, payload: { token, user } });
    localStorage.setItem("token", token);
    localStorage.setItem("user", JSON.stringify(user));
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const initLogin = async () => {
  const token = localStorage.getItem("token");
  const user = JSON.parse(localStorage.getItem("user")!)

  if (!user) {
    localStorage.removeItem("token")
    store.dispatch({ type: AuthActions.DelSession, payload: null });
  }

  if (token) {
    try {
      await client.get(`${API_URL}auth/validate`, {
        headers: {
          "x-auth-token": token,
        },
      });
      store.dispatch({ type: AuthActions.SetSession, payload: { token, user: JSON.parse(localStorage.getItem("user") || "{}") } });
    } catch (error) {
      localStorage.removeItem("token");
      store.dispatch({ type: AuthActions.DelSession, payload: null });
    }
  }
};

export const addUser = async (username: string, password: string, role: string, authorizedViews: string[]) => {
  try {
    const { data } = await client.post(`${API_URL}auth/addUser`, {
      username,
      password,
      role,
      authorizedViews,
    });
    store.dispatch({ type: UserActions.AddUser, payload: data });
    return true;
  } catch (error) {
    console.error(error);
    store.dispatch({ type: UserActions.SetError, payload: "Error agregando el usuario" });
    return false;
  }
};

export const updateUser = async (id: string, authorizedViews: string[]) => {
  try {
    const { data } = await client.patch(`${API_URL}auth/${id}`, {
      authorizedViews,
    });
    store.dispatch({ type: UserActions.UpdateUser, payload: data });
    return true;
  } catch (error) {
    console.error(error);
    store.dispatch({ type: UserActions.SetError, payload: "Error editando el usuario" });
    return false;
  }
};

export const deleteUser = async (id: string) => {
  try {
    const { data } = await client.delete(`${API_URL}auth/${id}`);
    store.dispatch({ type: UserActions.DeleteUser, payload: data });
    return true;
  } catch (error) {
    console.error(error);
    store.dispatch({ type: UserActions.SetError, payload: "Error eliminando el usuario" });
    return false;
  }
};

export const showAllUsers = async () => {
  try {
    const { data } = await client.get(`${API_URL}auth/showAll`);
    // remove trucks and sellers
    const filteredUsers = data.filter((user: User) => user.role !== "truck" && user.role !== "seller");

    store.dispatch({ type: UserActions.ReceiveUsers, payload: filteredUsers });
  } catch (error) {
    console.error(error);
    store.dispatch({ type: UserActions.SetError, payload: "Error obteniendo los usuarios" });
  }
};
