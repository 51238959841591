import {
  DataGrid,
  GridRenderCellParams,
  GridColDef,
  esES,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import { Button, Card, Alert } from "@mui/material";
import { useEffect, useState, useRef, ChangeEvent } from "react";
import { useAppSelector } from "../../../../hooks/storeHooks";
import { mdiCircleSlice1 } from "@mdi/js";
import Icon from "@mdi/react";
import {
  fetchSellers,
  deleteSeller,
  changePassword,
  resetDevices,
  updateSellersTargets,
} from "../../../../services/sellerService";
import NewSellerModal from "./NewSellerModal";
import EditPasswordModal from "./EditPasswordModal";
import { getPriceList } from "../../../../utils/dateManager";
import Logo from "../../../Logo";
import { ManageDeviceModal } from "./ManageDeviceModal";
import { getRowsToArraySellers } from "../../../../utils/readSellersExcel";
import ViewTargetsModal from "./ViewTargetsModal";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
    </GridToolbarContainer>
  );
}

const SellersView: React.FC = () => {
  const { isLoading, sellers } = useAppSelector(
    (state: any) => state.sellerReducer
  );
  const effectRan = useRef(false)
  const [modalOpen, setModalOpen] = useState(false);
  const [deviceModalOpen, setDeviceModalOpen] = useState(false);
  const [passwordModalOpen, setPasswordModalOpen] = useState(false);
  const [targetsModalOpen, setTargetsModalOpen] = useState(false);
  const [selectedSeller, setSelectedSeller] = useState<Seller | null>(null);
  const [successAlert, setSuccessAlert] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const columns: GridColDef[] = [
    { field: "name", minWidth: 150, flex: 1, headerName: "Nombre" },
    {
      field: "username",
      minWidth: 150,
      flex: 1,
      headerName: "Usuario",
    },
    {
      field: "manageDevices",
      minWidth: 150,
      flex: 1,
      headerName: "Gestionar Dispositivos",
      renderCell: (params: GridRenderCellParams) => (
        <Button
          sx={{ backgroundColor: "blue", color: "white" }}
          onClick={() => {
            for (let i = 0; i < sellers.length; i++) {
              if (sellers[i]._id === params.row._id) {
                setSelectedSeller(sellers[i]);
                setDeviceModalOpen(true);
              }
            }
          }}
        >
          Gestionar
        </Button>
      ),
    },
    {
      field: "priceList",
      minWidth: 150,
      flex: 1,
      headerName: "Lista de Precio Defecto",
      valueGetter(params) {
        return getPriceList(params.row.priceList);
      },
    },
    {
      field: "passwordChange",
      minWidth: 150,
      flex: 1,
      headerName: "Contraseña",
      renderCell(params) {
        return (
          <Button
            sx={{ backgroundColor: "orange", color: "white" }}
            onClick={() => {
              for (let i = 0; i < sellers.length; i++) {
                if (sellers[i]._id === params.row._id) {
                  setSelectedSeller(sellers[i]);
                  setPasswordModalOpen(true);
                }
              }
            }}
          >
            {" "}
            Cambiar Clave
          </Button>
        );
      },
    },
    {
      field: "edit",
      minWidth: 100,
      flex: 1,
      headerName: "Editar",
      renderCell: (params: GridRenderCellParams) => (
        <Button
          sx={{ backgroundColor: "blue", color: "white" }}
          onClick={() => {
            for (let i = 0; i < sellers.length; i++) {
              if (sellers[i]._id === params.row._id) {
                setSelectedSeller(sellers[i]);
                setModalOpen(true);
              }
            }
          }}
        >
          Editar
        </Button>
      ),
    },
    {
      field: "delete",
      minWidth: 100,
      flex: 1,
      headerName: "Borrar",
      renderCell: (params: GridRenderCellParams) => (
        <Button
          sx={{ backgroundColor: "red", color: "white" }}
          onClick={() => {
            deleteSeller(params.row._id);
          }}
        >
          Borrar
        </Button>
      ),
    },
    {
      field: "viewTargets",
      minWidth: 150,
      flex: 1,
      headerName: "Ver Metas",
      renderCell: (params: GridRenderCellParams) => (
        <Button
          sx={{ backgroundColor: "green", color: "white" }}
          onClick={() => {
            for (let i = 0; i < sellers.length; i++) {
              if (sellers[i]._id === params.row._id) {
                setSelectedSeller(sellers[i]);
                setTargetsModalOpen(true);
              }
            }
          }}
        >
          Ver Metas
        </Button>
      ),
    },
  ];

  useEffect(() => {
    if (effectRan.current === false) {
      fetchSellers();
      return () => {
        effectRan.current = true
      }
    }
  }, []);

  const handleFileUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files![0];
    if (file) {
      try {
        const sellersTargets = await getRowsToArraySellers(file);
        await updateSellersTargets(sellersTargets);
        alert("Metas cargadas correctamente")
      } catch (error) {
        alert(error)
      }
      finally {
        event.target.value = "";
      }
    }
  };

  return (
    <div className="p-5 h-full overflow-auto">
      <Logo />
      <div className="text-xl mb-5">Vendedores</div>
      <div className="mb-3 flex gap-2 justify-end pr-7">
        <Button
          variant="contained"
          onClick={() => {
            setSelectedSeller(null);
            setModalOpen(true);
          }}
        >
          {" "}
          Agregar
        </Button>
        <input
          type="file"
          accept=".xlsx, .xls"
          style={{ display: 'none' }}
          id="upload-excel"
          onChange={handleFileUpload}
        />
        <label htmlFor="upload-excel">
          <Button variant="contained" component="span">
            Subir Excel
          </Button>
        </label>
      </div>
      {successAlert && (
        <Alert severity="success" onClose={() => setSuccessAlert(false)}>
          Operación realizada con éxito
        </Alert>
      )}
      {errorAlert && (
        <Alert severity="error" onClose={() => setErrorAlert(false)}>
          Error al realizar operación
        </Alert>
      )}
      <div>
        {isLoading ? (
          <div className="flex justify-center w-full col-span-full">
            <Icon spin path={mdiCircleSlice1} className="w-7"></Icon>
          </div>
        ) : (
          <Card>
            <DataGrid
              sx={{ padding: 2 }}
              autoHeight={true}
              columns={columns}
              rows={sellers}
              pageSize={20}
              getRowId={(row) => row._id}
              localeText={esES.components.MuiDataGrid.defaultProps.localeText}
              components={{ Toolbar: CustomToolbar }}
            />
            <NewSellerModal
              open={modalOpen}
              seller={selectedSeller}
              setSeller={setSelectedSeller}
              onClose={() => setModalOpen(false)}
            />
            <EditPasswordModal
              open={passwordModalOpen}
              seller={selectedSeller}
              setSeller={setSelectedSeller}
              onClose={() => setPasswordModalOpen(false)}
              onSubmit={(body: { password: string; username: string }) => {
                changePassword(body)
                  .then((res) => {
                    if (res === true) {
                      setSuccessAlert(true);
                    } else {
                      setErrorAlert(true);
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                    setErrorAlert(true);
                  });
              }}
            />
            <ManageDeviceModal
              open={deviceModalOpen}
              seller={selectedSeller}
              onClose={() => setDeviceModalOpen(false)}
              onSubmit={(body: { id: string; deviceQty: number }) => {
                resetDevices(body)
                  .then((res) => {
                    if (res === true) {
                      setSuccessAlert(true);
                      setDeviceModalOpen(false);
                    } else {
                      setErrorAlert(true);
                      setDeviceModalOpen(false);
                    }
                  }
                  )
                  .catch((err) => {
                    console.log(err);
                    setErrorAlert(true);
                    setDeviceModalOpen(false);
                  }
                  );
              }}
            />
            <ViewTargetsModal
              open={targetsModalOpen}
              seller={selectedSeller}
              onClose={() => setTargetsModalOpen(false)}
            />
          </Card>
        )}
      </div>
    </div>
  );
};
export default SellersView;
