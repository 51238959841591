import React, { useEffect, useState, useRef } from "react";
import { useAppSelector } from "../../../../hooks/storeHooks";
import { showAllUsers, updateUser, deleteUser } from "../../../../services/authService";
import {
    DataGrid,
    GridRenderCellParams,
    GridColDef,
    esES,
    GridToolbarContainer,
    GridToolbarFilterButton,
    GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import { Button, Card, Alert, Chip } from "@mui/material";
import EditAuthorizedViewsModal from "./EditAuthorizedViewsModal";
import NewUserModal from "./NewUserModal";
import Logo from "../../../../components/Logo";

const UsersView: React.FC = () => {
    const { users, error } = useAppSelector((state) => state.UserReducer);
    const effectRan = useRef(false);
    const [editOpen, setEditOpen] = useState(false);
    const [newUserOpen, setNewUserOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState<any>(null);
    const [successAlert, setSuccessAlert] = useState(false);

    const columns: GridColDef[] = [
        { field: "username", minWidth: 150, flex: 1, headerName: "Nombre de Usuario" },
        {
            field: "role", minWidth: 150, flex: 1, headerName: "Rol",
            valueGetter(params) {
                return translateRoles(params.row.role)
            },
        },
        {
            field: "authorizedViews",
            minWidth: 150,
            flex: 1,
            headerName: "Vistas Autorizadas",
            renderCell: (params: GridRenderCellParams) => (
                <div>
                    {params.value.map((view: string) => (
                        <Chip key={view} label={view} />
                    ))}
                </div>
            ),
        },
        {
            field: "edit",
            minWidth: 100,
            flex: 1,
            headerName: "Editar",
            renderCell: (params: GridRenderCellParams) => (
                <Button
                    sx={{ backgroundColor: "blue", color: "white" }}
                    onClick={() => {
                        setSelectedUser(params.row);
                        setEditOpen(true);
                    }}
                >
                    Editar
                </Button>
            ),
        },
        {
            field: "delete",
            minWidth: 100,
            flex: 1,
            headerName: "Eliminar",
            renderCell: (params: GridRenderCellParams) => (
                <Button
                    sx={{ backgroundColor: "red", color: "white" }}
                    onClick={async () => {
                        const deleteUserResponse = await deleteUser(params.row._id);
                        if (deleteUserResponse) setSuccessAlert(true);
                    }}
                    disabled={params.row.role !== "employee"}
                >
                    Delete
                </Button>
            ),
        },
    ];

    useEffect(() => {
        if (effectRan.current === false) {
            showAllUsers();
            return () => {
                effectRan.current = true;
            };
        }
    }, []);

    const handleEditClose = () => {
        setEditOpen(false);
        setSelectedUser(null);
    };

    const handleEditSave = async (authorizedViews: string[]) => {
        if (selectedUser) {
            const update = await updateUser(selectedUser._id, authorizedViews);
            if (update) {
                setSuccessAlert(true);
            }
        }
    };

    const handleNewUserOpen = () => {
        setNewUserOpen(true);
    };

    const handleNewUserClose = () => {
        setNewUserOpen(false);
    };

    return (
        <div className="p-5 h-full overflow-auto">
            <Logo />
            <div className="text-xl mb-5">Users</div>
            <div className="mb-3 flex justify-end pr-7">
                <Button
                    variant="contained"
                    onClick={handleNewUserOpen}
                >
                    Agregar nuevo usuario
                </Button>
            </div>
            {successAlert && (
                <Alert severity="success" onClose={() => setSuccessAlert(false)}>
                    Operation exitosa
                </Alert>
            )}
            {error && (
                <Alert severity="error" onClose={() => { }}>
                    {error}
                </Alert>
            )}
            <div>
                <Card>
                    <DataGrid
                        sx={{ padding: 2 }}
                        autoHeight={true}
                        columns={columns}
                        rows={users}
                        pageSize={20}
                        getRowId={(row) => row._id}
                        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                        components={{ Toolbar: CustomToolbar }}
                    />
                    <EditAuthorizedViewsModal
                        open={editOpen}
                        onClose={handleEditClose}
                        onSave={handleEditSave}
                        initialViews={selectedUser ? selectedUser.authorizedViews : []}
                    />
                    <NewUserModal
                        open={newUserOpen}
                        onClose={handleNewUserClose}
                        onSuccess={() => setSuccessAlert(true)}
                    />
                </Card>
            </div>
        </div>
    );
};

const CustomToolbar = () => {
    return (
        <GridToolbarContainer>
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
        </GridToolbarContainer>
    );
};

export default UsersView;

const translateRoles = (role: string): string => {
    switch (role) {
        case "employee":
            return "Usuario Normal";
        case "logistic":
            return "App Logistica";
        case "supervisor":
            return "Supervisor";
        case "typist":
            return "Digitador"
        default:
            return "Rol Desconocido";
    }
};