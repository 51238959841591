interface State {
  users: User[];
  isLoading: boolean;
  error: string | null;
}

const initialState: State = {
  users: [],
  isLoading: false,
  error: null,
};

export enum UserActions {
  RequestUsers = "REQUEST_USERS",
  ReceiveUsers = "RECEIVE_USERS",
  AddUser = "ADD_USER",
  UpdateUser = "UPDATE_USER",
  DeleteUser = "DELETE_USER",
  SetError = "SET_ERROR",
}

const reducer = (
  state: State = initialState,
  { type, payload }: { type: UserActions; payload: any }
): State => {
  switch (type) {
    case UserActions.RequestUsers:
      return { ...state, isLoading: true, error: null };
    case UserActions.ReceiveUsers:
      return { ...state, users: payload, isLoading: false, error: null };
    case UserActions.AddUser:
      return {
        ...state,
        users: [...state.users, payload],
        error: null,
      };
    case UserActions.UpdateUser:
      return {
        ...state,
        users: state.users.map((user) =>
          user._id === payload._id ? payload : user
        ),
        error: null,
      };
    case UserActions.DeleteUser:
      return {
        ...state,
        users: state.users.filter((user) => user._id !== payload._id),
        error: null,
      };
    case UserActions.SetError:
      return { ...state, error: payload, isLoading: false };
    default:
      return state;
  }
};

export default reducer;
