import {
    DataGrid,
    GridRenderCellParams,
    GridColDef,
    esES,
    GridToolbarContainer,
    GridToolbarFilterButton,
    GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import { Button, Card, Alert } from "@mui/material";
import { useEffect, useState, useRef } from "react";
import { useAppSelector } from "../../../../hooks/storeHooks";
import { mdiCircleSlice1 } from "@mdi/js";
import Icon from "@mdi/react";
import NewSupervisorModal from "./NewSupervisorModal";
import EditPasswordModal from "./EditPasswordModal";
import Logo from "../../../Logo";
import { changePassword, deleteSupervisor, fetchSupervisors } from "../../../../services/supervisorService";

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
        </GridToolbarContainer>
    );
}

const SupervisorsView: React.FC = () => {
    const { isLoading, supervisors } = useAppSelector(
        (state: any) => state.supervisorReducer
    );
    const effectRan = useRef(false)
    const [modalOpen, setModalOpen] = useState(false);
    const [passwordModalOpen, setPasswordModalOpen] = useState(false);
    const [selectedSupervisor, setSelectedSupervisor] = useState<Supervisor | null>(null);
    const [successAlert, setSuccessAlert] = useState(false);
    const [errorAlert, setErrorAlert] = useState(false);
    const columns: GridColDef[] = [
        {
            field: "name",
            minWidth: 150,
            flex: 1,
            headerName: "Nombre"
        },
        {
            field: "username",
            minWidth: 150,
            flex: 1,
            headerName: "Usuario",
        },

        {
            field: "passwordChange",
            minWidth: 150,
            flex: 1,
            headerName: "Contraseña",
            renderCell(params) {
                return (
                    <Button
                        sx={{ backgroundColor: "orange", color: "white" }}
                        onClick={() => {
                            for (let i = 0; i < supervisors.length; i++) {
                                if (supervisors[i]._id === params.row._id) {
                                    setSelectedSupervisor(supervisors[i]);
                                    setPasswordModalOpen(true);
                                }
                            }
                        }}
                    >
                        {" "}
                        Cambiar Clave
                    </Button>
                );
            },
        },
        {
            field: "edit",
            minWidth: 100,
            flex: 1,
            headerName: "Editar",
            renderCell: (params: GridRenderCellParams) => (
                <Button
                    sx={{ backgroundColor: "blue", color: "white" }}
                    onClick={() => {
                        for (let i = 0; i < supervisors.length; i++) {
                            if (supervisors[i]._id === params.row._id) {
                                setSelectedSupervisor(supervisors[i]);
                                setModalOpen(true);
                            }
                        }
                    }}
                >
                    Editar
                </Button>
            ),
        },
        {
            field: "delete",
            minWidth: 100,
            flex: 1,
            headerName: "Borrar",
            renderCell: (params: GridRenderCellParams) => (
                <Button
                    sx={{ backgroundColor: "red", color: "white" }}
                    onClick={() => {
                        deleteSupervisor(params.row._id);
                    }}
                >
                    Borrar
                </Button>
            ),
        },
    ];

    useEffect(() => {
        if (effectRan.current === false) {
            fetchSupervisors();
            return () => {
                effectRan.current = true
            }
        }
    }, []);

    return (
        <div className="p-5 h-full overflow-auto">
            <Logo />
            <div className="text-xl mb-5">Supervisores</div>
            <div className="mb-3 flex justify-end pr-7">
                <Button
                    variant="contained"
                    onClick={() => {
                        setSelectedSupervisor(null);
                        setModalOpen(true);
                    }}
                >
                    {" "}
                    Agregar
                </Button>
            </div>
            {successAlert && (
                <Alert severity="success" onClose={() => setSuccessAlert(false)}>
                    Contraseña cambiada con éxito
                </Alert>
            )}
            {errorAlert && (
                <Alert severity="error" onClose={() => setErrorAlert(false)}>
                    Error al cambiar contraseña
                </Alert>
            )}
            <div>
                {isLoading ? (
                    <div className="flex justify-center w-full col-span-full">
                        <Icon spin path={mdiCircleSlice1} className="w-7"></Icon>
                    </div>
                ) : (
                    <Card>
                        <DataGrid
                            sx={{ padding: 2 }}
                            autoHeight={true}
                            columns={columns}
                            rows={supervisors}
                            pageSize={20}
                            getRowId={(row) => row._id}
                            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                            components={{ Toolbar: CustomToolbar }}
                        />
                        <NewSupervisorModal
                            open={modalOpen}
                            supervisor={selectedSupervisor}
                            setSupervisor={setSelectedSupervisor}
                            onClose={() => setModalOpen(false)}
                        />
                        <EditPasswordModal
                            open={passwordModalOpen}
                            supervisor={selectedSupervisor}
                            setSupervisor={setSelectedSupervisor}
                            onClose={() => setPasswordModalOpen(false)}
                            onSubmit={(body: { password: string; username: string }) => {
                                changePassword(body)
                                    .then((res) => {
                                        if (res === true) {
                                            setSuccessAlert(true);
                                        } else {
                                            setErrorAlert(true);
                                        }
                                    })
                                    .catch((err) => {
                                        console.log(err);
                                        setErrorAlert(true);
                                    });
                            }}
                        />
                    </Card>
                )}
            </div>
        </div>
    );
};
export default SupervisorsView;
