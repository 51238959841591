import React, { useEffect, useState } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Button, Card } from '@mui/material';
import { fetchPickers, createPicker } from '../../../../services/pickerService';
import NewPickerModal from './NewPickerModal';

const PickerListView: React.FC = () => {
    const [pickers, setPickers] = useState([]);
    const [newPickerOpen, setNewPickerOpen] = useState(false);

    useEffect(() => {
        const loadPickers = async () => {
            const response = await fetchPickers();
            setPickers(response);
        };
        loadPickers();
    }, []);

    const handleNewPicker = async (name: string) => {
        await createPicker({ name });
        const response = await fetchPickers();
        setPickers(response);
    };

    const columns: GridColDef[] = [
        { field: 'name', headerName: 'Nombre', width: 150 },
        // Add more columns as needed
    ];

    return (
        <div>
            <Button variant="contained" onClick={() => setNewPickerOpen(true)}>
                Agregar Picker
            </Button>
            <Card>
                <DataGrid
                    autoHeight
                    rows={pickers}
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    getRowId={(row) => row._id}
                />
            </Card>
            <NewPickerModal
                open={newPickerOpen}
                onClose={() => setNewPickerOpen(false)}
                onSave={handleNewPicker}
            />
        </div>
    );
};

export default PickerListView;
