import React, { useState } from "react";
import {
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from "@mui/material";

const authorizedViewOptions = [
    "Pendientes",
    "Inventarios",
    "Facturas",
    "Estadisticas",
    "Clientes",
    "Lista de Productos",
    "Catalogo",
    "Picking",
];

interface EditAuthorizedViewsModalProps {
    open: boolean;
    onClose: () => void;
    onSave: (authorizedViews: string[]) => void;
    initialViews: string[];
}

const EditAuthorizedViewsModal: React.FC<EditAuthorizedViewsModalProps> = ({
    open,
    onClose,
    onSave,
    initialViews,
}) => {
    const [authorizedViews, setAuthorizedViews] = useState<string[]>(initialViews);

    const handleSave = () => {
        onSave(authorizedViews);
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Editar Vistas Auotizadas</DialogTitle>
            <DialogContent>
                <FormControl fullWidth margin="normal">
                    <InputLabel>Vistas Autorizadas</InputLabel>
                    <Select
                        multiple
                        value={authorizedViews}
                        onChange={(e) => setAuthorizedViews(e.target.value as string[])}
                        renderValue={(selected) => (
                            <div style={{ display: "flex", flexWrap: "wrap", gap: 5 }}>
                                {(selected as string[]).map((view) => (
                                    <Chip key={view} label={view} />
                                ))}
                            </div>
                        )}
                    >
                        {authorizedViewOptions.map((view) => (
                            <MenuItem key={view} value={view}>
                                {view}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancelar</Button>
                <Button variant="contained" onClick={handleSave}>Guardar</Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditAuthorizedViewsModal;
