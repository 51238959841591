import React, { useState } from "react";
import {
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from "@mui/material";
import { addUser } from "../../../../services/authService";


const authorizedViewOptions = [
    "Pendientes",
    "Inventarios",
    "Facturas",
    "Estadisticas",
    "Clientes",
    "Lista de Productos",
    "Catalogo",
    "Picking",
];

interface NewUserModalProps {
    open: boolean;
    onClose: () => void;
    onSuccess: () => void;
}

const NewUserModal: React.FC<NewUserModalProps> = ({ open, onClose, onSuccess }) => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [authorizedViews, setAuthorizedViews] = useState<string[]>([]);

    const handleSave = async () => {
        const addUserResponse = await addUser(username, password, "employee", authorizedViews);
        if (addUserResponse) {
            onSuccess();
        }
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Agregar Nuevo Usuario</DialogTitle>
            <DialogContent>
                <TextField
                    fullWidth
                    margin="normal"
                    label="Username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                />
                <TextField
                    fullWidth
                    margin="normal"
                    label="Password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <FormControl fullWidth margin="normal">
                    <InputLabel>Vistas Autorizadas</InputLabel>
                    <Select
                        multiple
                        value={authorizedViews}
                        onChange={(e) => setAuthorizedViews(e.target.value as string[])}
                        renderValue={(selected) => (
                            <div style={{ display: "flex", flexWrap: "wrap", gap: 5 }}>
                                {(selected as string[]).map((view) => (
                                    <Chip key={view} label={view} />
                                ))}
                            </div>
                        )}
                    >
                        {authorizedViewOptions.map((view) => (
                            <MenuItem key={view} value={view}>
                                {view}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancelar</Button>
                <Button variant="contained" onClick={handleSave}>Guardar</Button>
            </DialogActions>
        </Dialog>
    );
};

export default NewUserModal;
