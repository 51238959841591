import React, { useState } from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import OrderWithPickersView from './OrderWithPickersView';
import PickerListView from './PickerListView';

const PickersView: React.FC = () => {
    const [tab, setTab] = useState<'orders' | 'pickers'>('orders');

    return (
        <div className="p-5 h-full overflow-auto">
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                    value={tab}
                    onChange={(_, v) => setTab(v as 'orders' | 'pickers')}
                    aria-label="picking tabs"
                >
                    <Tab label="Ordenes" value="orders" />
                    <Tab label="Pickers" value="pickers" />
                </Tabs>
            </Box>
            <div className="flex-1 overflow-auto">
                {tab === 'orders' && <OrderWithPickersView />}
                {tab === 'pickers' && <PickerListView />}
            </div>
        </div>
    );
};

export default PickersView;
