import Excel from "exceljs";

// Esta función debe recibir un archivo excel y desglosar todos los datos y enviarlos en un array
export async function getRowsToArraySellers(file) {
  const reader = new FileReader();
  const wb = new Excel.Workbook();
  reader.readAsArrayBuffer(file);
  return new Promise((resolve, reject) => {
    reader.onload = () => {
      const buffer = reader.result;
      let rowsToArray = [];
      wb.xlsx
        .load(buffer)
        .then((workbook) => {
          workbook.worksheets[0].eachRow((row, rowNumber) => {
            try {
              if (rowNumber !== 1) {
                let rowToArray = {
                  username: row.getCell(1).text,
                  januaryTarget: parseInt(row.getCell(2).value) || 0,
                  februaryTarget: parseInt(row.getCell(3).value) || 0,
                  marchTarget: parseInt(row.getCell(4).value) || 0,
                  aprilTarget: parseInt(row.getCell(5).value) || 0,
                  mayTarget: parseInt(row.getCell(6).value) || 0,
                  juneTarget: parseInt(row.getCell(7).value) || 0,
                  julyTarget: parseInt(row.getCell(8).value) || 0,
                  augustTarget: parseInt(row.getCell(9).value) || 0,
                  septemberTarget: parseInt(row.getCell(10).value) || 0,
                  octoberTarget: parseInt(row.getCell(11).value) || 0,
                  novemberTarget: parseInt(row.getCell(12).value) || 0,
                  decemberTarget: parseInt(row.getCell(13).value) || 0,
                };
                rowsToArray.push(rowToArray);
              }
            } catch (error) {
              reject(error);
            }
          });
        })
        .then(() => {
          resolve(rowsToArray);
        })
        .catch((error) => {
          reject(error);
        });
    };
  });
}
