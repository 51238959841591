import { Box, Button, Card, LinearProgress, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../../../hooks/storeHooks";
import Logo from "../../../Logo";
import { fetchSellers } from "../../../../services/sellerService";
import { fetchSupervisors } from "../../../../services/supervisorService";
import { mdiCircleSlice1 } from "@mdi/js";
import Icon from "@mdi/react";
import { styled } from '@mui/material/styles';
import handleGenerateWorkerAchievementExcel from "../../../../utils/generateWorkerAchievmentExcel";



const monthNames: { [key: number]: string } = {
    0: "Enero",
    1: "Febrero",
    2: "Marzo",
    3: "Abril",
    4: "Mayo",
    5: "Junio",
    6: "Julio",
    7: "Agosto",
    8: "Septiembre",
    9: "Octubre",
    10: "Noviembre",
    11: "Diciembre"
}

const CustomLinearProgress = styled(LinearProgress)(({ value }) => ({
    height: 10,
    '& .MuiLinearProgress-bar': {
        backgroundColor: getProgressColor(value!),
    },
}));

const calculateSellerPercentages = (seller: Seller, currentMonth: number, currentQuarter: number) => {
    const monthSales = [
        seller.month1sales,
        seller.month2sales,
        seller.month3sales,
        seller.month4sales,
        seller.month5sales,
        seller.month6sales,
        seller.month7sales,
        seller.month8sales,
        seller.month9sales,
        seller.month10sales,
        seller.month11sales,
        seller.month12sales,
    ];
    const monthTargets = [
        seller.januaryTarget,
        seller.februaryTarget,
        seller.marchTarget,
        seller.aprilTarget,
        seller.mayTarget,
        seller.juneTarget,
        seller.julyTarget,
        seller.augustTarget,
        seller.septemberTarget,
        seller.octoberTarget,
        seller.novemberTarget,
        seller.decemberTarget,
    ];

    const getQuarterSales = (quarter: number) => {
        if (quarter === currentQuarter) {
            if (currentMonth % 3 === 1) {
                return monthSales[0];
            } else if (currentMonth % 3 === 2) {
                return monthSales[0] + monthSales[1];
            } else {
                return monthSales[0] + monthSales[1] + monthSales[2];
            }
        } else if (quarter === currentQuarter - 1) {
            if (currentMonth % 3 === 1) {
                return monthSales[1] + monthSales[2] + monthSales[3];
            } else if (currentMonth % 3 === 2) {
                return monthSales[2] + monthSales[3] + monthSales[4];
            } else {
                return monthSales[3] + monthSales[4] + monthSales[5];
            }
        }
        return 0;
    };
    const getQuarterTarget = (quarter: number) => {
        const startMonth = (quarter - 1) * 3;
        return monthTargets.slice(startMonth, startMonth + 3).reduce((a, b) => a + b, 0);
    };

    const monthTarget = [
        monthTargets[currentMonth - 1],
        currentMonth > 1 ? monthTargets[currentMonth - 2] : 0,
        currentMonth > 2 ? monthTargets[currentMonth - 3] : 0,
        currentMonth > 3 ? monthTargets[currentMonth - 4] : 0,
        currentMonth > 4 ? monthTargets[currentMonth - 5] : 0,
        currentMonth > 5 ? monthTargets[currentMonth - 6] : 0,
        currentMonth > 6 ? monthTargets[currentMonth - 7] : 0,
        currentMonth > 7 ? monthTargets[currentMonth - 8] : 0,
        currentMonth > 8 ? monthTargets[currentMonth - 9] : 0,
        currentMonth > 9 ? monthTargets[currentMonth - 10] : 0,
        currentMonth > 10 ? monthTargets[currentMonth - 11] : 0,
        currentMonth > 11 ? monthTargets[currentMonth - 12] : 0,
    ];

    const quarterSales = [
        getQuarterSales(currentQuarter),
        currentQuarter > 1 ? getQuarterSales(currentQuarter - 1) : 0,
        currentQuarter > 2 ? getQuarterSales(currentQuarter - 2) : 0,
        currentQuarter > 3 ? getQuarterSales(currentQuarter - 3) : 0,
    ];

    const quarterTargets = [
        getQuarterTarget(currentQuarter),
        currentQuarter > 1 ? getQuarterTarget(currentQuarter - 1) : 0,
        currentQuarter > 2 ? getQuarterTarget(currentQuarter - 2) : 0,
        currentQuarter > 3 ? getQuarterTarget(currentQuarter - 3) : 0,
    ];

    const monthPercentages = [
        calculateProgress(monthSales[0], monthTarget[0]),
        calculateProgress(monthSales[1], monthTarget[1]),
        calculateProgress(monthSales[2], monthTarget[2]),
        calculateProgress(monthSales[3], monthTarget[3]),
        calculateProgress(monthSales[4], monthTarget[4]),
        calculateProgress(monthSales[5], monthTarget[5]),
        calculateProgress(monthSales[6], monthTarget[6]),
        calculateProgress(monthSales[7], monthTarget[7]),
        calculateProgress(monthSales[8], monthTarget[8]),
        calculateProgress(monthSales[9], monthTarget[9]),
        calculateProgress(monthSales[10], monthTarget[10]),
        calculateProgress(monthSales[11], monthTarget[11]),
    ];

    const quarterPercentages = [
        calculateProgress(quarterSales[0], quarterTargets[0]),
        calculateProgress(quarterSales[1], quarterTargets[1]),
        calculateProgress(quarterSales[2], quarterTargets[2]),
        calculateProgress(quarterSales[3], quarterTargets[3]),
    ];



    const cleanData = {
        name: seller.name,
        monthSales,
        monthTarget,
        quarterSales,
        quarterTargets,
        monthPercentages,
        quarterPercentages,
    };
    return cleanData;
};

const calculateProgress = (sales: number, target: number) => {
    return Math.floor((sales / target) * 100);
};

const getProgressColor = (value: number) => {
    if (value <= 50) {
        return 'red';
    } else if (value <= 95) {
        return 'yellow';
    } else if (value > 95) {
        return 'green';
    } else {
        return '#aaaaaa';
    }
};

const WorkerAchievementsView: React.FC = () => {
    const [ rows, setRows ] = useState<any[]>([]);
    const { sellers, isLoading } = useAppSelector((store) => store.sellerReducer);
    const { supervisors } = useAppSelector((store) => store.supervisorReducer);
    const user = useAppSelector((store) => store.authReducer.user);
    const [filter, setFilter] = useState<string>("");
    const [ loading, setLoading ] = useState<boolean>(false);
    const currentMonth = new Date().getMonth() + 1;
    const currentQuarter = Math.ceil(currentMonth / 3);


    useEffect(() => {
        fetchSellers();
        fetchSupervisors();
    }, []);

    useEffect(() => {
        let rowData: any[] = [];

        if (!!user && user.role === "manager") {
            rowData = sellers.map((seller) => {
                const sellerPercentages = calculateSellerPercentages(seller, currentMonth, currentQuarter);
                return sellerPercentages;
            });

        } else if (!!user && user.role === "supervisor") {
            const matchedSupervisor = supervisors.find((supervisor) => supervisor._id === user.supervisor._id);
            const filteredSellers = sellers.filter((seller) => matchedSupervisor!.subordinates.includes(seller._id));

            rowData = filteredSellers.map((seller) => {
                const sellerPercentages = calculateSellerPercentages(seller, currentMonth, currentQuarter);
                return sellerPercentages;
            });
        };

        setRows(rowData);
    }, [sellers]);

    const handleGenerateReport = async () => {
        setLoading(true);
        await handleGenerateWorkerAchievementExcel(rows, currentMonth, currentQuarter);
        setLoading(false);
    };

    return (
        <div className="p-5 overflow-auto mt-5">
            <Logo />

            {isLoading ? (
                <div className="flex justify-center w-full col-span-full">
                    <Icon spin path={mdiCircleSlice1} className="w-7"></Icon>
                </div>
                ) : (
                <Card className="p-5 my-5">
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="h4">Progreso por vendedor</Typography>

                        <Box>
                            <TextField
                                label="Filtrar por nombre"
                                variant="outlined"
                                size="small"
                                value={filter}
                                onChange={(e) => setFilter(e.target.value)}
                            />

                            <Button
                                style={{ marginLeft: "10px" }}
                                variant='contained'
                                color='primary'
                                onClick={ () => {handleGenerateReport()} }
                                disabled={isLoading || loading}
                            >
                                Generar Reporte
                            </Button>
                        </Box>
                        
                    </Box>

                    {
                        rows.filter((row) =>
                            row.name.toLowerCase().includes(filter.toLowerCase())
                        ).map((row) => {
                            return (
                                <Card key={row.name} className="p-5 my-5">
                                    <Typography variant="h6">{row.name}</Typography>

                                    <br />

                                    {row.monthTarget.map((target:any, index:number) => (target > 0 && (
                                        <Box display="flex" flexDirection="column" alignItems="flex-start" marginBottom={2}>
                                            <Typography variant="h6">Progreso Mes {monthNames[currentMonth-index-1]}</Typography>
                                        
                                            <Box display="flex" gap={3}>
                                                <Typography>Ventas Mes: ${row.monthSales[index].toLocaleString('es-CL')}</Typography>
                                                <Typography>Meta Mes: ${row.monthTarget[index].toLocaleString('es-CL')}</Typography>
                                            </Box>
                                        
                                            <Box display="flex" alignItems="center" width="100%">
                                                <Box width="100%" mr={1}>
                                                    <CustomLinearProgress 
                                                        variant="determinate" 
                                                        value={row.monthPercentages[index] <= 100 ? row.monthPercentages[index] : 100} 
                                                    />
                                                </Box>
                                                <Box minWidth={35}>
                                                    <Typography variant="body2" color="textSecondary">
                                                        {`${row.monthPercentages[index]}%`}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Box>) 
                                    ))}

                                    {
                                        (row.monthTarget[0] === 0) && (
                                            <Box display="flex" flexDirection="column" alignItems="flex-start" marginBottom={2}>
                                                <Typography variant="h6">Progreso Mes {monthNames[currentMonth-1]}: N/A</Typography>
                                            </Box>
                                        )
                                    }

                                    <br />
                                    <br />

                                    {row.quarterTargets.map((target:any, index:number) => (target > 0 && (
                                        <Box key={index} display="flex" flexDirection="column" alignItems="flex-start" marginBottom={2}>
                                            <Typography variant="h6">Progreso Trimestre {currentQuarter - index}</Typography>

                                            <Box display="flex" gap={3}>
                                                <Typography>Ventas Trimestre: ${row.quarterSales[index].toLocaleString('es-CL')}</Typography>
                                                <Typography>Meta Trimestre: ${row.quarterTargets[index].toLocaleString('es-CL')}</Typography>
                                            </Box>

                                            <Box display="flex" alignItems="center" width="100%">
                                                <Box width="100%" mr={1}>
                                                    <CustomLinearProgress
                                                        variant="determinate"
                                                        value={row.quarterPercentages[index] <= 100 ? row.quarterPercentages[index] : 100}
                                                    />
                                                </Box>
                                                <Box minWidth={35}>
                                                    <Typography variant="body2" color="textSecondary">
                                                        {`${row.quarterPercentages[index]}%`}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Box>)
                                    ))}

                                    {
                                        (row.quarterTargets[0] === 0) && (
                                            <Box display="flex" flexDirection="column" alignItems="flex-start" marginBottom={2}>
                                                <Typography variant="h6">Progreso Trimestre {currentQuarter}: N/A</Typography>
                                            </Box>
                                        )
                                    }
                                    
                                </Card>
                            );
                        })
                    }
                </Card>
            )};
        </div>
    );
};

export default WorkerAchievementsView;

