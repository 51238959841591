import axios from './client';


const API_URL = process.env.REACT_APP_API_URL;

export const fetchPickers = async () => {
    try {
  const response = await axios.get(`${API_URL}/pickers`);
  return response.data;
    } catch (error) {
        alert("Error al traer los pickers");
        console.error(error);
        return [];
    }
};

export const createPicker = async (picker: Partial<Picker>) => {
    try {
        const response = await axios.post(`${API_URL}/pickers`, picker);
        return response.data;
    } catch (error) {
        alert("Error al crear el picker");
        console.error(error);
        return null;
    }
};