import {
  DataGrid,
  GridColDef,
  esES,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import { Alert, Button, Card } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { useAppSelector } from "../../../../hooks/storeHooks";
import { getRowsToArray } from "../../../../utils/readExcel";
import { updateFullCatalog } from "../../../../services/productService";
import store from "../../../../store";
import { ProductActions } from "../../../../reducers/ProductReducer";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
    </GridToolbarContainer>
  );
}

const PriceView: React.FC = () => {
  const { products, errorProducts, errorMsg, success } = useAppSelector(
    (state: any) => state.productReducer
  );
  const [errorAlert, setErrorAlert] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);

  const columns: GridColDef[] = [
    { field: "code", minWidth: 150, flex: 1, headerName: "Codigo" },
    { field: "description", minWidth: 350, flex: 1, headerName: "Descripcion" },
    { field: "brand", minWidth: 150, flex: 1, headerName: "Marca" },
    {
      field: "priceRM",
      minWidth: 150,
      flex: 1,
      headerName: "Precio RM y V",
      valueGetter(params) {
        return params.row.priceRM.toLocaleString("es-CL", {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        });
      },
    },
    {
      field: "priceMaule",
      minWidth: 150,
      flex: 1,
      headerName: "Precio Maule",
      valueGetter(params) {
        return params.row.priceMaule.toLocaleString("es-CL", {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        });
      },
    },
    {
      field: "priceCoquimbo",
      minWidth: 150,
      flex: 1,
      headerName: "Precio Coquimbo",
      valueGetter(params) {
        return params.row.priceCoquimbo.toLocaleString("es-CL", {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        });
      },
    },
    {
      field: "priceVI",
      minWidth: 150,
      flex: 1,
      headerName: "precio O´Higgins",
      valueGetter(params) {
        return params.row.priceVI.toLocaleString("es-CL", {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        });
      },
    },
    {
      field: "priceConstitucion",
      minWidth: 150,
      flex: 1,
      headerName: "Precio Constitucion",
      valueGetter(params) {
        return params.row.priceConstitucion.toLocaleString("es-CL", {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        });
      },
    },
    {
      field: "priceÑuble",
      minWidth: 150,
      flex: 1,
      headerName: "Precio Ñuble",
      valueGetter(params) {
        return params.row.priceÑuble.toLocaleString("es-CL", {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        });
      },
    },
    {
      field: "priceBiobio",
      minWidth: 150,
      flex: 1,
      headerName: "Precio Bio Bio",
      valueGetter(params) {
        return params.row.priceBiobio.toLocaleString("es-CL", {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        });
      },
    },
    {
      field: "priceAraucania",
      minWidth: 150,
      flex: 1,
      headerName: "Precio Araucania",
      valueGetter(params) {
        return params.row.priceAraucania.toLocaleString("es-CL", {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        });
      },
    },
    {
      field: "priceLosRios",
      minWidth: 150,
      flex: 1,
      headerName: "Los Rios Los Lagos",
      valueGetter(params) {
        return params.row.priceLosRios.toLocaleString("es-CL", {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        });
      },
    },
    {
      field: "priceChiloe",
      minWidth: 150,
      flex: 1,
      headerName: "Precio Chiloe",
      valueGetter(params) {
        return params.row.priceChiloe.toLocaleString("es-CL", {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        });
      },
    },
    {
      field: "priceVallenar",
      minWidth: 150,
      flex: 1,
      headerName: "Precio Vallenar",
      valueGetter(params) {
        return params.row.priceVallenar.toLocaleString("es-CL", {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        });
      },
    },
    {
      field: "priceCopiapo",
      minWidth: 150,
      flex: 1,
      headerName: "Precio Copiapo",
      valueGetter(params) {
        return params.row.priceCopiapo.toLocaleString("es-CL", {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        });
      },
    },
    {
      field: "priceCaldera",
      minWidth: 150,
      flex: 1,
      headerName: "Precio Caldera",
      valueGetter(params) {
        return params.row.priceCaldera.toLocaleString("es-CL", {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        });
      },
    },
  ];

  function handleUpload(
    e: ChangeEvent<HTMLInputElement>,
    typeOfUpload: string
  ): void {
    const file = e.target.files![0];
    getRowsToArray(file, typeOfUpload)
      .then((rows: any[]) => {
        updateFullCatalog(rows);
        e.target.value = "";
      })
      .catch((err) => {
        if (err.includes("Error en el formato")) {
          store.dispatch({
            type: ProductActions.UpdateErrorMsg,
            payload: err,
          });
          setErrorAlert(true);
        }
        e.target.value = "";
      });
  }
  useEffect(() => {
    if (errorProducts.length > 0) {
      setErrorAlert(true);
    } else if (errorMsg !== "") {
      setErrorAlert(true);
    }
  }, [errorProducts, errorMsg]);
  useEffect(() => {
    if (success) {
      setShowSuccessAlert(true);
    }
  }, [success]);
  return (
    <div className="h-full overflow-auto">
      {errorAlert && (
        <Alert
          severity="error"
          onClose={() => {
            setErrorAlert(false);
          }}
        >
          {errorMsg !== "" ? errorMsg : "Hay un error en las filas"}{" "}
          {errorProducts.map((error: { row: number }) => {
            return error.row + ", ";
          })}
        </Alert>
      )}
      {showSuccessAlert && (
        <Alert
          severity="success"
          onClose={() => {
            setShowSuccessAlert(false);
          }}
        >
          Se han subido los productos correctamente
        </Alert>
      )}

      <div className=" mt-2 mb-3 flex justify-end pr-7 space-x-2">
        <input
          accept=".xls, .xlsx"
          style={{ display: "none" }}
          id="contained-button-file"
          type="file"
          onChange={(e) => handleUpload(e, "Productos")}
        />
        <label htmlFor="contained-button-file">
          <Button
            variant="contained"
            color="secondary"
            size="large"
            component="span"
          >
            Carga Masiva Precios
          </Button>
        </label>
      </div>
      <div>
        <Card>
          <DataGrid
            sx={{ padding: 2 }}
            autoHeight={true}
            columns={columns}
            rows={products}
            pageSize={20}
            getRowId={(row) => row._id}
            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
            components={{ Toolbar: CustomToolbar }}
          />
        </Card>
      </div>
    </div>
  );
};

export default PriceView;
