import React, { useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
} from '@mui/material';

interface NewPickerModalProps {
    open: boolean;
    onClose: () => void;
    onSave: (name: string) => void;
}

const NewPickerModal: React.FC<NewPickerModalProps> = ({ open, onClose, onSave }) => {
    const [name, setName] = useState('');

    const handleSave = () => {
        onSave(name);
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Agregar Picker</DialogTitle>
            <DialogContent>
                <TextField
                    fullWidth
                    margin="normal"
                    label="Nombre"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancelar</Button>
                <Button onClick={handleSave}>Guardar</Button>
            </DialogActions>
        </Dialog>
    );
};

export default NewPickerModal;
