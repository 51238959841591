import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

const handleGenerateOrdersExcel = async (data) => {
    // Crear un nuevo libro de trabajo
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Listado de Órdenes');

    worksheet.columns = [
        { header: 'ID Red Pine', key: 'dateInMilliseconds', width: 15 },
        { header: 'Laudus ID', key: 'laudusId', width: 15 },
        { header: 'Cliente', key: 'customerName', width: 25 },
        { header: 'Fecha', key: 'date', width: 15 },
        { header: 'Total', key: 'total', width: 15 },
        { header: 'Picker', key: 'pickerName', width: 25 },
    ];

    data.forEach((order) => {
        worksheet.addRow({
            dateInMilliseconds: order.dateInMilliseconds,
            laudusId: order.laudusId,
            customerName: order.customer.name,
            date: new Date(order.date).toLocaleDateString(),
            total: order.total,
            pickerName: order.pickerName,
        });
    });

    // Generar archivo Excel y guardarlo
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, 'Listado_Ordenes.xlsx');
};

export default handleGenerateOrdersExcel;
