import {
    DataGrid,
    GridColDef,
    esES,
    GridToolbarContainer,
    GridToolbarFilterButton,
    GridToolbarDensitySelector,
} from '@mui/x-data-grid';

import { useAppSelector } from '../../../../hooks/storeHooks';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Button, Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select } from '@mui/material';
import { fetchOrdersWithInvoiceAndPendingProducts } from '../../../../services/invoiceService';
import { SetStateAction, useEffect, useState } from 'react';
import DetailedOrderView from '../../Common/Order/DetailedOrderView';
import PendingProductsModal from './PendingProductsModal';
import InvoicesModal from './InvoicesModal';
import ComboBox from '../../../Combobox';
import { generateQuotationPDF } from '../../../../services/quotationService';
import handleGenerateExcelOrdersWithInvoices from '../../../../utils/generateOrdersWithInvoices';
import handleGenerateExcelOrdersWithInvoicesByProduct from '../../../../utils/generateOrdersWithInvoicesByProduct';

function CustomToolbar({ onDownloadExcelGrouped, onDownloadExcelByProduct }: { onDownloadExcelGrouped: () => void, onDownloadExcelByProduct: () => void }) {
    return (
        <GridToolbarContainer>
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
            <div className='flex gap-2'>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={onDownloadExcelByProduct}
                >
                    Descargar Excel Por Producto
                </Button>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={onDownloadExcelGrouped}
                    style={{ marginLeft: 'auto' }}
                >
                    Descargar Excel Agrupado
                </Button>
            </div>
        </GridToolbarContainer>
    );
}

const useModalState = () => {
    const [openModal, setOpenModal] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [openInvoicesModal, setOpenInvoicesModal] = useState(false);
    const [openPendingModal, setOpenPendingModal] = useState(false);

    return { openModal, setOpenModal, selectedOrder, setSelectedOrder, openInvoicesModal, setOpenInvoicesModal, openPendingModal, setOpenPendingModal };
};

const useSellerName = () => {
    const { sellers } = useAppSelector((state: any) => state.sellerReducer);
    const getSellerName = (sellerId: string) => {
        const seller = sellers.find(
            (seller: { _id: string }) => seller._id === sellerId
        );
        return seller ? seller.name : '';
    };
    return getSellerName;
};

const useColumns = (getSellerName: (sellerId: string) => string,
    setOpenInvoicesModal: { (value: SetStateAction<boolean>): void; (arg0: boolean): void; },
    setOpenPendingModal: { (value: SetStateAction<boolean>): void; (arg0: boolean): void; },
    setSelectedOrder: ((arg0: any) => void),
) => {

    const columns: GridColDef[] = [
        {
            field: 'dateInMilliseconds',
            minWidth: 200,
            flex: 1,
            headerName: 'Numero Referencia',
        },
        {
            field: 'date',
            minWidth: 100,
            flex: 1,
            headerName: 'Fecha',
            valueGetter(params) {
                const date = new Date(params.row.date);
                return `${date.getDate()}/${date.getMonth() + 1
                    }/${date.getFullYear()} `;
            },
            sortComparator: (v1, v2) => {
                const date1 = new Date(
                    `${v1.split('/')[1]}-${v1.split('/')[0]}-${v1.split('/')[2]}`
                );
                const date2 = new Date(
                    `${v2.split('/')[1]}-${v2.split('/')[0]}-${v2.split('/')[2]}`
                );
                const date1Time = date1.getTime();
                const date2Time = date2.getTime();
                return date1Time - date2Time;
            },
        },
        {
            field: 'customer',
            minWidth: 100,
            flex: 1,
            headerName: 'Cliente',
            valueGetter(params) {
                return params.row.customer.name;
            }
        },
        {
            field: 'seller',
            minWidth: 300,
            flex: 1,
            headerName: 'Vendedor',
            valueGetter(params) {
                return getSellerName(params.row.seller);
            },
        },
        {
            field: 'discountedNetTotal',
            minWidth: 100,
            flex: 1,
            headerName: 'Total Neto con descuentos',
            valueGetter(params) {
                return params.row.discountedNetTotal.toLocaleString('es-CL', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                });
            },
            sortComparator: (v1, v2) => {
                // Convertimos el formato de texto a números para realizar la comparación
                const value1 = Number(v1.replace(/\./g, '')); // Quitamos puntos (separadores de miles)
                const value2 = Number(v2.replace(/\./g, ''));

                return value1 - value2; // Ordenar de menor a mayor
            },

        },
        {
            field: 'pendingProducts',
            minWidth: 100,
            flex: 1,
            headerName: 'Productos Pendientes',
            renderCell: (params) => {
                return (
                    <Button
                        onClick={() => {
                            setSelectedOrder(params.row);
                            setOpenPendingModal(true);
                        }}
                        variant="contained"
                        color="primary"
                    >
                        Ver Productos Pendientes
                    </Button>
                );
            },
        },
        {
            field: 'totalPendingProducts',
            minWidth: 100,
            flex: 1,
            headerName: 'Total Productos Pendientes ($)',
            valueGetter(params) {
                return params.row.pendingProducts.reduce(
                    (acc: number, product: any) => {
                        return acc + product.netPrice * product.quantity;
                    },
                    0
                ).toLocaleString('es-CL', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                });
            },
            sortComparator: (v1, v2) => {
                // Convertimos el formato de texto a números para realizar la comparación
                const value1 = Number(v1.replace(/\./g, '')); // Quitamos puntos (separadores de miles)
                const value2 = Number(v2.replace(/\./g, ''));

                return value1 - value2; // Ordenar de menor a mayor
            },
        },
        {
            field: 'invoices',
            minWidth: 100,
            flex: 1,
            headerName: 'Facturas',
            renderCell: (params) => {
                return (
                    <Button
                        onClick={() => {
                            setSelectedOrder(params.row);
                            setOpenInvoicesModal(true);
                        }}
                        variant="contained"
                        color="primary"
                    >
                        Ver Facturas
                    </Button>
                );
            },
        },
        {
            field: 'hasQuotation',
            minWidth: 100,
            flex: 1,
            headerName: 'Tiene Cotización',
            valueGetter(params) {
                return params.row.quotation ? 'Si' : 'No';
            },
        },
        {
            field: 'quotationAmount',
            minWidth: 100,
            flex: 1,
            headerName: 'Monto Cotización',
            valueGetter(params) {
                return params.row.quotation
                    ? params.row.quotation.discountedNetTotal.toLocaleString('es-CL', {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                    })
                    : '';
            },
        },
        {
            field: 'downloadQuotation',
            minWidth: 100,
            flex: 1,
            headerName: 'Descargar Cotización',
            renderCell: (params) => {
                return (
                    <Button
                        disabled={!params.row.quotation}
                        sx={{ backgroundColor: "#EC0013", color: "white" }}
                        onClick={() => {
                            const downloadName = `${params.row.quotation._id}`;

                            generateQuotationPDF(params.row.quotation._id, downloadName);
                        }}
                    >
                        Cotizacion PDF
                    </Button>
                );
            },
        },
        {
            field: "newStatus",
            minWidth: 200,
            flex: 1,
            headerName: "Convertido en Pedido Nuevamente",
            valueGetter(params) {
                if (params.row.quotation && params.row.quotation.convertedToOrder) {
                    return "Si";
                }
                return "No";
            },
        },

    ]

    return columns;
}

const useDataGrid = (columns: GridColDef[],
    startDate: Date | null,
    setStartDate: (date: Date | null) => void,
    endDate: Date | null,
    setEndDate: (date: Date | null) => void,
    filterType: string,
    setFilterType: (filterType: "order" | "invoice" | "") => void,
    openModal: boolean,
    setOpenModal: (value: SetStateAction<boolean>) => void,
    selectedOrder: any,
    setSelectedOrder: (value: SetStateAction<any>) => void,
    openInvoicesModal: boolean,
    setOpenInvoicesModal: (value: SetStateAction<boolean>) => void,
    openPendingModal: boolean,
    setOpenPendingModal: (value: SetStateAction<boolean>) => void,

) => {

    const { ordersWithInvoiceAndPendingProducts } = useAppSelector((state) => state.pendingReducer);
    const { products } = useAppSelector((state) => state.productReducer);
    const [product, setProduct] = useState<Product | null>(null);
    const [hasQuotation, setHasQuotation] = useState(false);
    const { sellers } = useAppSelector((state) => state.sellerReducer);
    const [filteredRows, setFilteredRows] = useState(ordersWithInvoiceAndPendingProducts);

    const handleDownloadExcelGrouped = () => {
        handleGenerateExcelOrdersWithInvoices(filteredRows);
    };

    const handleDownloadExcelByProduct = () => {
        handleGenerateExcelOrdersWithInvoicesByProduct(filteredRows, sellers);
    }

    useEffect(() => {
        let filtered = ordersWithInvoiceAndPendingProducts;
        if (product) {
            filtered = filtered.filter((row: any) => {
                return row.pendingProducts.find((pendingProduct: any) => pendingProduct.code === product.code);
            });
        }
        if (hasQuotation) {
            filtered = filtered.filter((row: any) => !!row.quotation);
        }
        setFilteredRows(filtered);
    }, [product, hasQuotation, ordersWithInvoiceAndPendingProducts]);

    return (
        <>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <div className="my-2 flex gap-2">
                    <DatePicker
                        sx={{ minWidth: '200px' }}
                        format='dd/MM/yyyy'
                        label="Fecha de inicio"
                        value={startDate}
                        onChange={(newValue) => {
                            if (newValue) {
                                newValue.setHours(0);
                                newValue.setMinutes(0);
                            }
                            setStartDate(newValue);
                        }}
                    />
                    <DatePicker
                        sx={{ minWidth: '200px' }}
                        format='dd/MM/yyyy'
                        label="Fecha de fin"
                        value={endDate}
                        onChange={(newValue) => {
                            if (newValue) {
                                newValue.setHours(23);
                                newValue.setMinutes(59);
                            }
                            setEndDate(newValue);
                        }}
                    />
                    <FormControl sx={{ minWidth: '200px' }}>
                        <InputLabel id="filter-select">Tipo de Filtro</InputLabel>
                        <Select
                            labelId="filter-select"
                            id='filter-select'
                            className="mb-3"
                            fullWidth
                            label="Tipo de filtro"
                            onChange={(e) => {
                                setFilterType(e.target.value as "order" | "invoice" | "");
                            }}
                            value={filterType}
                        >
                            <MenuItem value="">Sin Filtro</MenuItem>
                            <MenuItem value="order">Orden</MenuItem>
                            <MenuItem value="invoice">Factura</MenuItem>
                        </Select>
                    </FormControl>
                    <Button
                        sx={{ minWidth: '200px' }}
                        variant='contained'
                        disabled={!startDate || !endDate || !filterType}
                        onClick={() => {
                            fetchOrdersWithInvoiceAndPendingProducts(startDate!, endDate!, filterType);
                        }}
                    >
                        Actualizar
                    </Button>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={hasQuotation}
                                onChange={(e) => setHasQuotation(e.target.checked)}
                                color="primary"
                            />
                        }
                        label="Filtrar con Cotizacion"
                    />
                    <ComboBox<Product>
                        value={product as Product}
                        selectValue="code"
                        secondarySelectValue="description"
                        onChange={(product: Product) => {
                            setProduct(product);
                        }}
                        options={products}
                        label="Productos"
                    ></ComboBox>
                </div>

            </LocalizationProvider>
            <DataGrid
                autoHeight
                rows={filteredRows}
                columns={columns}
                pageSize={100}
                getRowId={(row) => row._id}
                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                components={{
                    Toolbar: () => <CustomToolbar onDownloadExcelGrouped={handleDownloadExcelGrouped} onDownloadExcelByProduct={handleDownloadExcelByProduct} />,
                }}
                onRowDoubleClick={(row) => {
                    setSelectedOrder(row.row);
                    setOpenModal(true);
                }}
            />
            <DetailedOrderView
                open={openModal}
                onClose={() => setOpenModal(false)}
                order={selectedOrder}
            ></DetailedOrderView>
            <InvoicesModal
                open={openInvoicesModal}
                onClose={() => setOpenInvoicesModal(false)}
                order={selectedOrder}
            />
            <PendingProductsModal
                open={openPendingModal}
                onClose={() => setOpenPendingModal(false)}
                order={selectedOrder}
            />
        </>
    );
}

const OrdersWithInvoices = ({
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    filterType,
    setFilterType,
}: {
    startDate: Date | null;
    setStartDate: (date: Date | null) => void;
    endDate: Date | null;
    setEndDate: (date: Date | null) => void;
    filterType: string;
    setFilterType: (type: "order" | "invoice" | "") => void;
}) => {
    const { openModal, setOpenModal, selectedOrder, setSelectedOrder, openInvoicesModal, setOpenInvoicesModal, openPendingModal, setOpenPendingModal } =
        useModalState();
    const columns = useColumns(useSellerName(), setOpenInvoicesModal, setOpenPendingModal, setSelectedOrder);
    return useDataGrid(columns, startDate, setStartDate, endDate, setEndDate, filterType, setFilterType, openModal, setOpenModal, selectedOrder, setSelectedOrder, openInvoicesModal, setOpenInvoicesModal, openPendingModal, setOpenPendingModal);
};

export default OrdersWithInvoices;